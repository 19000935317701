.Unab {
  text-align: center;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

.Unab img {
  width: 90%;
  height: auto;
}